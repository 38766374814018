import { SVGProps } from "react"

export const Subtract = (props: SVGProps<SVGSVGElement>) => (
  <div className="mt-[-1px]">
    <svg width="1em" height="1em" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20.645C15.5228 20.645 20 16.1679 20 10.645C20 5.12217 15.5228 0.64502 10 0.64502C4.47715 0.64502 0 5.12217 0 10.645C0 16.1679 4.47715 20.645 10 20.645ZM14.592 8.10551C14.8463 7.77855 14.7874 7.30734 14.4605 7.05304C14.1335 6.79874 13.6623 6.85764 13.408 7.1846L9.40099 12.3365C9.31189 12.451 9.14429 12.4659 9.03641 12.3688L6.50173 10.0876C6.19385 9.81049 5.71963 9.83545 5.44254 10.1433C5.16544 10.4512 5.1904 10.9254 5.49828 11.2025L8.03296 13.4837C8.78809 14.1634 9.9613 14.0593 10.585 13.2574L14.592 8.10551Z"
        fill="currentColor"
      />
    </svg>
  </div>
)
