import { PatientParIdentifiantQueryQuery, StatutProchainDepistage } from "@data/gql/graphql"
import { useTranslation } from "react-i18next"
import { isAfter, isBefore, isEqual } from "date-fns"
import { CardDepistage } from "../components/CardDepistage"
import { classNames } from "@ds"

export type DepistagesProps = {
  patient: PatientParIdentifiantQueryQuery["patientParIdentifiant"]
}

export const Depistages = ({ patient }: DepistagesProps) => {
  const { t } = useTranslation()

  const today = Date.now()
  const depistagesEligibles = patient.depistages
    .filter((depistage) => isBefore(depistage.dateDEligibilite, today) || isEqual(depistage.dateDEligibilite, today))
    .sort((a, b) => {
      const order = [StatutProchainDepistage.EnRetard, StatutProchainDepistage.AFaire, StatutProchainDepistage.AJour]
      const statutComparison = order.indexOf(a.statutProchainDepistage) - order.indexOf(b.statutProchainDepistage)
      if (statutComparison !== 0) return statutComparison
      return new Date(a.dateProchainDepistage).getTime() - new Date(b.dateProchainDepistage).getTime()
    })
  const depistagesNonEligibles = patient.depistages.filter((depistage) => isAfter(depistage.dateDEligibilite, today))

  return (
    <>
      <div className={classNames("text-h4", patient.depistages.length == 0 ? "mb-2" : "mb-6")} data-testid="depistages">
        {t("depistagesAFaire")}
      </div>
      {patient.depistages.length == 0 && (
        <div className="text-p-tiny italic text-grey">{t("eligibleAPartirde16ans")}</div>
      )}
      {depistagesEligibles.length > 0 && (
        <div className="mb-6">
          <div className="mb-2 text-h7 text-grey">{t("eligibles").toUpperCase()}</div>
          <div className="flex flex-col gap-2">
            {depistagesEligibles.map((depistage) => (
              <CardDepistage depistage={depistage} key={depistage.id} />
            ))}
          </div>
        </div>
      )}
      {depistagesNonEligibles.length > 0 && (
        <>
          <div className="mb-2 text-h7 text-grey">{t("bientotEligibles").toUpperCase()}</div>
          <div className="flex flex-col gap-2">
            {depistagesNonEligibles.map((depistage) => (
              <CardDepistage depistage={depistage} key={depistage.nom} />
            ))}
          </div>
        </>
      )}
    </>
  )
}
