import { graphql } from "@data/gql"

export const enregistrerDepistage = graphql(`
  mutation enregistrerDepistage(
    $identifiantProfilPatient: String!
    $identifiantDepistage: String!
    $statutDepistage: StatutDepistage!
    $dateDepistage: LocalDate
  ) {
    enregistrerDepistage(
      identifiantProfilPatient: $identifiantProfilPatient
      identifiantDepistage: $identifiantDepistage
      statutDepistage: $statutDepistage
      dateDepistage: $dateDepistage
    ) {
      id
    }
  }
`)
