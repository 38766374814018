import { StatutProchainDepistage } from "@data/gql/graphql"
import { EnteteTag, Subtract, WarningSolid } from "@ds"
import { useTranslation } from "react-i18next"

export const TagStatut = ({ statut }: { statut: StatutProchainDepistage }) => {
  const { t } = useTranslation()
  switch (statut) {
    case StatutProchainDepistage.AFaire:
      return (
        <EnteteTag
          icon={<WarningSolid className="text-h6 text-error" />}
          content={t("aFaire")}
          color={"bg-extra-light-grey text-dark-plum"}
          upperCase={false}
        />
      )
    case StatutProchainDepistage.AJour:
      return (
        <EnteteTag
          icon={<Subtract className="text-h6 text-valid" />}
          content={t("aJour")}
          color={"bg-secondary-green text-dark-plum"}
          upperCase={false}
        />
      )
    case StatutProchainDepistage.EnRetard:
      return (
        <EnteteTag
          icon={<WarningSolid className="text-h6 text-error" />}
          content={t("enRetard")}
          color={"bg-secondary-red text-dark-plum"}
          upperCase={false}
        />
      )
    case StatutProchainDepistage.NonRenseigne:
      return <EnteteTag content={t("nonRenseigne")} color={"bg-extra-light-grey"} upperCase={false} />
  }
}
