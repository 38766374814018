import { useTranslation } from "react-i18next"

export const LigneDepistage = ({
  libelle,
  valeur,
  testId,
}: {
  libelle: string
  valeur: string | number | undefined
  testId: string
}) => {
  const { t } = useTranslation()
  return (
    <div className="ml-6 flex max-w-lg items-center justify-between gap-2" data-testid={testId}>
      <div className="px-2 py-1 text-p-tiny">{libelle}</div>
      <div className="px-2 py-1 text-h6 text-dark-plum">{valeur || t("nonRenseigne")}</div>
    </div>
  )
}
