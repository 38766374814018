import { SidePanel } from "@ds"
import { contextePatientPanelRoute, preconsultationRoute } from "@infra/navigation/router"
import { useNavigate, useRouter } from "@tanstack/react-router"
import { ContextePatient } from "../components/ContextePatient"
import { BoutonMettreEnFormeSyntheseContexte } from "../components/BoutonMettreEnFormeSyntheseContexte"
import { PatientParIdentifiantQueryQuery } from "@data/gql/graphql"
import { Depistages } from "./Depistages"
import { useFlags } from "launchdarkly-react-client-sdk"

export const ContextePatientPanel = () => {
  const navigate = useNavigate()
  const { id } = preconsultationRoute.useParams()
  const { identifiantProfil } = contextePatientPanelRoute.useParams()
  const close = () => navigate({ to: preconsultationRoute.to, params: { id } })
  const { visualisationDepistagesConsole } = useFlags()
  const router = useRouter()

  const { data } = contextePatientPanelRoute.useLoaderData()
  const patientParIdentifiant = data?.patientParIdentifiant

  return (
    <SidePanel onClose={close}>
      <ContextePatient
        identifiantProfil={identifiantProfil}
        align={"column"}
        patient={patientParIdentifiant as PatientParIdentifiantQueryQuery["patientParIdentifiant"]}
        boutonMettreEnForme={BoutonMettreEnFormeSyntheseContexte}
      />
      {visualisationDepistagesConsole && (
        <Depistages
          patient={patientParIdentifiant as PatientParIdentifiantQueryQuery["patientParIdentifiant"]}
          onChange={() => router.invalidate()}
        />
      )}
    </SidePanel>
  )
}
