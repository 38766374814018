import { PatientParIdentifiantQueryQuery, StatutDepistage, StatutProchainDepistage } from "@data/gql/graphql"
import { ChevronDown, ChevronUp } from "@ds"
import { useTranslation } from "react-i18next"
import { format, getYear } from "date-fns"
import { useState } from "react"
import { useFlags } from "launchdarkly-react-client-sdk"
import { LigneDepistage } from "./LigneDepistage"
import { TagStatut } from "./TagStatut"
import { LigneDateDepistage } from "./LigneDateDepistage"
import { useGQLMutation } from "@data/useGraphQL.ts"
import { enregistrerDepistage } from "@data/patient/contextePatient/enregistrerDepistage.ts"
import { patientParIdentifiantQuery } from "@data/patient/patientParIdentifiantQuery.ts"
import { useRouter } from "@tanstack/react-router"

export type CardDepistageProps = {
  depistage: PatientParIdentifiantQueryQuery["patientParIdentifiant"]["depistages"][0]
}

export const CardDepistage = ({ depistage }: CardDepistageProps) => {
  // TODO: remonter le useRouter(), refacto les composants cards
  const [ouverte, setOuverte] = useState(
    depistage.statutProchainDepistage === StatutProchainDepistage.AFaire ||
      depistage.statutProchainDepistage === StatutProchainDepistage.EnRetard,
  )

  return (
    <div
      className="flex flex-col gap-2 rounded-xl p-4 shadow-very-light-shadow"
      data-testid={"depistage-" + depistage.id}
    >
      <div className="flex justify-between">
        <div className="flex cursor-pointer gap-1" onClick={() => setOuverte(!ouverte)}>
          {ouverte && <ChevronUp />}
          {!ouverte && <ChevronDown />}
          <div className="text-h4">{depistage.nom}</div>
        </div>
        <TagStatut statut={depistage.statutProchainDepistage} />
      </div>
      {ouverte && (
        <div className="flex flex-col">
          <EvaluationDuRisque depistage={depistage} />

          <QuestionSupplementaireRisque depistage={depistage} />

          <DernierDepistage depistage={depistage} />

          <ProchainDepistage depistage={depistage} />

          <DescriptionExamen depistage={depistage} />
        </div>
      )}
    </div>
  )
}

const QuestionSupplementaireRisque = ({ depistage }: CardDepistageProps) => {
  const { t } = useTranslation()
  const { modificationDepistagesConsole } = useFlags()
  const router = useRouter()

  const depistageFait = () => {
    switch (depistage.statutDernierDepistage) {
      case StatutDepistage.Fait:
        return t("oui")
      case StatutDepistage.NonFait:
        return t("non")
      case null:
        return ""
    }
  }

  const { mutate: enregistrerStatutDepistage } = useGQLMutation(enregistrerDepistage, {
    invalidateQueryKeys: [[patientParIdentifiantQuery, { identifiant: depistage.patientId }]],
    onSuccess: () => {
      router.invalidate()
    },
  })

  const onSelect = (valeur: string) => {
    switch (valeur) {
      case t("oui"):
        enregistrerStatutDepistage({
          identifiantProfilPatient: depistage.patientId,
          identifiantDepistage: depistage.id,
          statutDepistage: StatutDepistage.Fait,
          dateDepistage: null,
        })
        break
      case t("non"):
        enregistrerStatutDepistage({
          identifiantProfilPatient: depistage.patientId,
          identifiantDepistage: depistage.id,
          statutDepistage: StatutDepistage.NonFait,
          dateDepistage: null,
        })
        break
    }
  }

  return (
    <>
      {!depistage.questionDateDernierExamen && modificationDepistagesConsole && (
        <LigneDateDepistage
          libelle={depistage.questionDepistage}
          choix={[t("oui"), t("non")]}
          valeur={depistageFait()}
          onSelect={(valeur) => onSelect(valeur)}
          testId="questionSupplementaireRisque"
        />
      )}
      {!depistage.questionDateDernierExamen && !modificationDepistagesConsole && (
        <LigneDepistage
          libelle={depistage.questionDepistage}
          valeur={depistageFait()}
          testId="questionSupplementaireRisque"
        />
      )}
    </>
  )
}

const EvaluationDuRisque = ({ depistage }: CardDepistageProps) => {
  const { t } = useTranslation()
  const { modificationDepistagesConsole } = useFlags()
  const router = useRouter()

  const aRisque = () => {
    switch (depistage.statutDernierDepistage) {
      case StatutDepistage.PasARisque:
        return t("non")
      case null:
        return ""
      default:
        return t("oui")
    }
  }

  const { mutate: enregistrerEvaluationDuRisque } = useGQLMutation(enregistrerDepistage, {
    invalidateQueryKeys: [[patientParIdentifiantQuery, { identifiant: depistage.patientId }]],
    onSuccess: () => {
      router.invalidate()
    },
  })

  const onSelect = (valeur: string) => {
    switch (valeur) {
      case t("oui"):
        enregistrerEvaluationDuRisque({
          identifiantProfilPatient: depistage.patientId,
          identifiantDepistage: depistage.id,
          statutDepistage: StatutDepistage.ARisque,
          dateDepistage: null,
        })
        break
      case t("non"):
        enregistrerEvaluationDuRisque({
          identifiantProfilPatient: depistage.patientId,
          identifiantDepistage: depistage.id,
          statutDepistage: StatutDepistage.PasARisque,
          dateDepistage: null,
        })
        break
    }
  }

  return (
    <>
      {depistage.evaluationDuRisque && modificationDepistagesConsole && (
        <LigneDateDepistage
          libelle={depistage.evaluationDuRisque}
          choix={[t("oui"), t("non")]}
          valeur={aRisque()}
          onSelect={(valeur) => onSelect(valeur)}
          testId="evaluationDuRisque"
        />
      )}
      {depistage.evaluationDuRisque && !modificationDepistagesConsole && (
        <LigneDepistage libelle={depistage.evaluationDuRisque} valeur={aRisque()} testId="evaluationDuRisque" />
      )}
    </>
  )
}

const DernierDepistage = ({ depistage }: CardDepistageProps) => {
  const { modificationDepistagesConsole } = useFlags()
  const { t } = useTranslation()
  const router = useRouter()

  const { mutate: enregistrerDateDernierDepistage } = useGQLMutation(enregistrerDepistage, {
    invalidateQueryKeys: [[patientParIdentifiantQuery, { identifiant: depistage.patientId }]],
    onSuccess: () => {
      router.invalidate()
    },
  })

  const dateDernierDepistage = () => {
    switch (depistage.statutDernierDepistage) {
      case StatutDepistage.Fait:
        return depistage.dateDernierDepistage ? getYear(depistage.dateDernierDepistage) : t("dateInconnue")
      case StatutDepistage.NeSaitPas:
      case StatutDepistage.NonFait:
        return t("jamaisFait")
      case null:
        return ""
    }
  }

  const changerDateDernierDepistage = (valeur: string) => {
    switch (valeur) {
      case t("jamaisFait"):
        enregistrerDateDernierDepistage({
          identifiantProfilPatient: depistage.patientId,
          identifiantDepistage: depistage.id,
          statutDepistage: StatutDepistage.NonFait,
          dateDepistage: null,
        })
        break
      case t("dateInconnue"):
        enregistrerDateDernierDepistage({
          identifiantProfilPatient: depistage.patientId,
          identifiantDepistage: depistage.id,
          statutDepistage: StatutDepistage.Fait,
          dateDepistage: null,
        })
        break
      default:
        const dateDernierDepistage = format(new Date(parseInt(valeur), 0), "yyyy-MM-dd")
        enregistrerDateDernierDepistage({
          identifiantProfilPatient: depistage.patientId,
          identifiantDepistage: depistage.id,
          statutDepistage: StatutDepistage.Fait,
          dateDepistage: dateDernierDepistage as unknown as Date,
        })
        break
    }
  }

  const anneeCourante = new Date().getFullYear()
  const dixDernieresAnnees = Array.from({ length: 10 }, (_, i) => anneeCourante - i)
  const choixAnnees = [...dixDernieresAnnees, t("jamaisFait"), t("dateInconnue")]

  return (
    <>
      {depistage.questionDateDernierExamen && (
        <>
          {modificationDepistagesConsole && (
            <LigneDateDepistage
              libelle={t("dernierDepistage")}
              choix={choixAnnees}
              valeur={dateDernierDepistage()}
              onSelect={(valeur) => changerDateDernierDepistage(valeur)}
              testId="dateDernierDepistage"
            />
          )}
          {!modificationDepistagesConsole && (
            <LigneDepistage
              libelle={t("dernierDepistage")}
              valeur={dateDernierDepistage()}
              testId="dateDernierDepistage"
            />
          )}
        </>
      )}
    </>
  )
}

const ProchainDepistage = ({ depistage }: CardDepistageProps) => {
  const { t } = useTranslation()
  const { modificationDepistagesConsole } = useFlags()

  return (
    <>
      {!depistage.evaluationDuRisque && depistage.dateDernierDepistage && (
        <>
          {modificationDepistagesConsole && (
            <LigneDateDepistage
              libelle={t("prochainDepistage")}
              choix={[getYear(depistage.dateProchainDepistage)]}
              valeur={getYear(depistage.dateProchainDepistage)}
              onSelect={() => {}}
              testId="prochainDepistage"
              disabled={true}
            />
          )}
          {!modificationDepistagesConsole && (
            <LigneDepistage
              libelle={t("prochainDepistage")}
              valeur={getYear(depistage.dateProchainDepistage)}
              testId="prochainDepistage"
            />
          )}
        </>
      )}
    </>
  )
}

const DescriptionExamen = ({ depistage }: CardDepistageProps) => {
  const { t } = useTranslation()

  return (
    <>
      {depistage.description && (
        <div className="flex max-w-lg items-center justify-between gap-2" data-testid="description">
          <div className="px-2 py-1 text-p-tiny text-dark-grey">{depistage.description}</div>
        </div>
      )}
      {depistage.examen && (
        <div className="flex max-w-lg items-center justify-between gap-2" data-testid="conditions">
          <div
            className="px-2 py-1 text-p-tiny text-dark-grey"
            // à cause de bilan sanguin & prélèvement
            dangerouslySetInnerHTML={{ __html: t("examen", { nom: depistage.examen }) }}
          ></div>
        </div>
      )}
    </>
  )
}
